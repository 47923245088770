import {applyPolyfills} from '@logistics-frontend/polyfills'
import React, {StrictMode} from 'react'
import {createRoot} from 'react-dom/client'

import App from './App'

applyPolyfills()

const root = createRoot(document.getElementById('order-widget-root'))

root.render(
  <StrictMode>
    <App />
  </StrictMode>,
)
