import Input from '@logistics-frontend/blocks/v2/input/Input'
import {SuggestObject} from '@yandex-taxi/js-integration-api'
import React, {useMemo, useState} from 'react'

import ListItem from './ListItem'
import {classes, iconClasses} from './Suggest.classes'

interface Props {
  type: 'source' | 'destination'
  placeholder?: string
  part?: string
  geoObject?: SuggestObject
  suggest?: SuggestObject[]
  loading?: boolean
  onInputChange: (part: string) => void
  onInputBlur?: any
  onInputFocus?: any
  helperText?: React.ReactNode
}

const Suggest: React.FC<Props> = props => {
  const {
    type = 'source',
    helperText,
    part = '',
    geoObject = null,
    suggest = [],
    loading = false,
    placeholder,
    onInputChange,
    onInputBlur,
    onInputFocus,
  } = props
  const inputProps = useMemo(
    () => ({
      placeholder,
      helperText,
      error: !!helperText,
      startAdornment: <div className={`${iconClasses.icon} ${type === 'source' ? iconClasses.icon_source : ''}`} />,
      endAdornment: loading ? (
        <div className={classes.loading} />
      ) : (
        <button
          className={classes.clearInput}
          aria-label="Clear address"
          onClick={() => {
            onInputChange(null)
          }}
        />
      ),
      onBlur: onInputBlur,
      onFocus: onInputFocus,
      autoComplete: 'off',
      autoCapitalize: 'none',
      spellCheck: false,
    }),
    [placeholder, helperText, type, loading, onInputBlur, onInputFocus, onInputChange],
  )

  const [optionsOpened, setOptionsOpened] = useState(false)

  const openHandler = () => {
    setOptionsOpened(true)
  }
  const closeHandler = () => {
    setOptionsOpened(false)
  }

  const selectPoint = point => {
    onInputChange(point)
    setOptionsOpened(false)
  }

  return (
    <div className={classes.root} onMouseDown={openHandler} onBlur={closeHandler}>
      <Input
        className={classes.input}
        value={part}
        onChange={event => {
          const inputValue = event.target.value
          onInputChange(inputValue)
          openHandler()
        }}
        {...inputProps}
      />
      {optionsOpened && (
        <div className={classes.popup}>
          <div className={classes.listbox}>
            {loading ? (
              <ListItem subtitle="загружаю..." />
            ) : suggest.length > 0 ? (
              suggest.map((point, index) => (
                <ListItem
                  key={index}
                  title={point.title.text}
                  onClick={() => selectPoint(point)}
                  subtitle={point.subtitle.text}
                  selected={point.text === geoObject?.text}
                />
              ))
            ) : (
              part && <ListItem subtitle="ничего не найдено" />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

export default Suggest
