import {Point} from '@yandex-taxi/js-integration-api'
import {
  AsyncAuthorization,
  AsyncState,
  AsyncStatus,
  DEFAULT_ZONE_POINT,
  Draft,
  DraftAddress,
  IntegrationApiProvider,
  toInitialState,
} from '@yandex-taxi/react-integration-api'
import React, {useEffect, useMemo, useState} from 'react'

import {startAuthorization} from './api'
import {Form} from './components/form'

const fallback = (state: AsyncAuthorization) => <>{state.status === AsyncStatus.ERROR && state.error?.message}</>

const App: React.FC = () => {
  const [asyncPoints, setAsyncPoints] = useState<AsyncState<Point>>({status: AsyncStatus.PENDING})
  const initialState = useMemo(() => {
    const initialState = toInitialState({coords: asyncPoints.data})()
    const route: DraftAddress[] = [{address: {point: asyncPoints.data}}, {}]
    Object.assign<Draft, Draft>(initialState.draft, {
      values: {
        route,
      },
    })
    return initialState
  }, [asyncPoints])

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      ({coords}) => {
        setAsyncPoints({
          status: AsyncStatus.SUCCESS,
          data: [coords.longitude, coords.latitude],
        })
      },
      () =>
        setAsyncPoints({
          status: AsyncStatus.SUCCESS,
          data: DEFAULT_ZONE_POINT,
        }),
      {enableHighAccuracy: true},
    )
  }, [])

  if (asyncPoints.status === AsyncStatus.SUCCESS && asyncPoints.data) {
    return (
      <IntegrationApiProvider apiInstance={startAuthorization} initialState={initialState}>
        <Form />
      </IntegrationApiProvider>
    )
  }

  return fallback(asyncPoints)
}

export default App
