import {css} from '@logistics-frontend/blocks/theme/css'

import {spacing} from '../consts'

export const classes = {
  container: css`
    display: flex;
    box-sizing: border-box;
    margin: -${spacing}px;
    width: calc(100% + ${2 * spacing}px);
  `,
  item: css`
    padding: ${spacing}px;
    flex-grow: 0;
    max-width: 100%;
    flex-basis: 100%;
  `,
  column: css`
    flex-direction: column;
  `,
}
