import {RoutePoint} from '@logistics-frontend/types/schemas/cargo-matcher/definitions'
import {DraftAddress, toPoint} from '@yandex-taxi/react-integration-api'

export const toRoutePoints = (route: DraftAddress[]): RoutePoint[] =>
  route
    .map(item => toPoint(item?.address?.geoObject?.pos))
    .filter(Boolean)
    .map(coordinates => ({coordinates}))

const FALLBACK_CURRENCY = 'RUB'

export const localizePrice = (
  price: string | number,
  currency: string = FALLBACK_CURRENCY,
  locale: string = 'ru-RU',
): string => {
  const value = typeof price === 'string' ? parseFloat(price) : price

  try {
    return value.toLocaleString(locale, {style: 'currency', currency}).replace(',00', '')
  } catch (err) {
    //если пришел невалидный currency код, то покажем валюту рубли
    return value.toLocaleString(locale, {style: 'currency', currency: FALLBACK_CURRENCY}).replace(',00', '')
  }
}

export const isEmpty = obj => {
  return !Object.keys(obj || {})[0]
}
