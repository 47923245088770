import {useRoute, useZoneInfoQuery} from '@yandex-taxi/react-integration-api'
import React from 'react'

import {Address} from '../address'
import {Grid} from '../grid'
import {Tariffs} from '../tariffs'
import {classes} from './Form.classes'

const Form: React.FC = () => {
  const [{value: route = []}] = useRoute()

  useZoneInfoQuery()

  return (
    <div className={classes.root}>
      <div className={classes.title}>Рассчитать стоимость</div>
      <Grid container column>
        {route.map((_, index) => (
          <Grid key={index}>
            <Address index={index} />
          </Grid>
        ))}
        <Tariffs />
      </Grid>
    </div>
  )
}

export default Form
