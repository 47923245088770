// TODO: сделать это Suspense чтобы грузить одновременно с другими запросами
export const applyPolyfills = () => {
  const polyfills: Promise<unknown>[] = []

  if (!('allSettled' in Promise)) {
    polyfills.push(import(/* webpackChunkName: "promise-all-settled" */ './promise-all-settled'))
  }

  return Promise.all(polyfills)
}
