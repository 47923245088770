export enum Tariffs {
  Courier = 'courier',
  Express = 'express',
  Cargo = 'cargo',
}

export interface Price {
  value: string
  currency: string
}

export type TariffPrices = Record<Tariffs, Price>

export type RequestBody = any
type RequestHeader = string
export interface RequestHeaders {
  [key: string]: RequestHeader
}
type ResponseHeader = string
export interface ResponseHeaders {
  [key: string]: ResponseHeader
}
type CredentialOption = 'include' | 'same-origin' | 'omit'
export interface NetworkOptions {
  body?: RequestBody
  headers?: RequestHeaders
  credentials?: CredentialOption
}

interface RequestConfig {
  url: string
  key?: string
  method?: string
  options?: NetworkOptions
  headers?: RequestHeaders
  update?: any
  body?: RequestBody
  transform?: any
  meta?: any
  force?: boolean
}

export type TypedRequestConfig<TRequestBody = any, TResponseBody = any, TTransformedBody = any> = Omit<
  RequestConfig,
  'body' | 'transform'
> & {
  body?: TRequestBody
  transform?: (res: TResponseBody) => TTransformedBody
  signal?: AbortSignal
}
