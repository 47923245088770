import {css} from '@logistics-frontend/blocks/theme/css'

import {breakpoints} from '../consts'

const clearInput = css`
  width: 20px;
  height: 20px;
  padding: 0;
  background: #000;
  border-radius: 50%;
  border: none;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  cursor: pointer;

  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 12px;
    height: 2px;
    background: #fff;
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
`

export const classes = {
  root: css`
    position: relative;
    color: #000;

    & > div > div {
      border-radius: 8px;

      &:focus-within {
        border-color: rgb(252, 185, 2);
        background: #f2f1ee;
      }

      &:focus-within .${clearInput} {
        opacity: 0.25;
      }

      @media (hover: hover) {
        .${clearInput} {
          opacity: 0.25;
        }
      }

      & + div {
        border-radius: 0;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  `,
  popup: css`
    position: absolute;
    background: #fff;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.4);
    margin: 0;
    border-radius: 8px;
    overflow: hidden;
    width: 100%;
    z-index: 10;
  `,
  listbox: css`
    overflow-y: auto;
    scrollbar-width: thin;
    max-height: 240px;
  `,
  option: css`
    &[data-focus='true'] {
      border-radius: 8px;
      background: #f2f1ee;
    }
  `,
  input: css`
    height: 19px;
    font-size: 16px;
    ${breakpoints.mobile} {
      font-size: 12px;
      height: auto;
    }

    &:placeholder-shown + div .${clearInput} {
      display: none;
    }
  `,
  loading: css`
    width: 14px;
    height: 14px;
    border-radius: 50%;
    border: solid 3px #369;
    position: relative;
    opacity: 0.3;
    animation: loader-rotate 1s infinite

    &:after {
      content: '';
      position: absolute;
      left: 80%;
      top: -10%;
      width: 12px;
      height: 12px;
      background: #f5f4f2;
      transform: rotate(-45deg) skew(30deg);
    }
    @keyframes loader-rotate {
      0% {transform:rotate(0deg)}
      100%{transform:rotate(360deg)}}
  `,
  clearInput,
}

export const iconClasses = {
  icon: css`
    width: 7px;
    height: 7px;
    border: 3px solid #21201f;
    border-radius: 50%;
    box-sizing: content-box;
  `,
  icon_source: css`
    border-color: #fb482d;
  `,
}
