import {css} from '@logistics-frontend/blocks/theme/css'

export const classes = {
  listItem: css`
    display: flex;
    min-height: 48px;
    width: 100%;
    cursor: pointer;
    border-radius: 8px;
    border-bottom: 1px solid transparent;
    color: rgba(33, 32, 31, 1);
    font-family: Yandex Sans Text Web;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: normal;
    line-height: 17px;

    &:hover {
      background-color: #F2F1EE;
    },
  `,

  selected: css`
    background-color: #f6f5f3;
  `,

  body: css`
    font-family: Yandex Sans Text Web;
    font-weight: 400;
    display: flex;
    padding: 6px;
    padding-left: 12px;
    align-items: center;
    flex-grow: 1;
    min-height: 32px;
    overflow: hidden;
    position: relative;
    flex-wrap: wrap;
  `,
  titleText: css`
    font-size: 13px;
    line-height: 17px;
    width: 100%;
  `,
  subtitleText: css`
    font-size: 11px;
    line-height: 15px;
    width: 100%;
    letter-spacing: 0.01em;
    color: rgba(158, 155, 152, 1);
  `,
}
