import {css} from '../../theme/css'
import {hex2rgba, spacing} from '../../theme/theme'
import {makeStyles} from '../../theme/UNSTABLE_makeStyles'

export const Theme = {
  Default: {
    Day: {
      Control: '#fce000',
      ControlMinor: '#f1f0ed',
      Background: '#ffffff',
      BackgroundInvert: '#302f2d',
      EverBlack: '#000000',
      BackgroundMinor: '#f5f4f2',
      Text: '#21201f',
      TextMinor: '#9e9b98',
      Line: '#d2d0cc',
      LineWeb: 'rgba(210, 208, 204, 0.5)',
      // Day/Focus и Day/Hover скорее всего инородные, не из дефолтной темы
      Focus: 'rgba(0, 0, 0, 0.75)',
      Hover: 'rgba(0, 0, 0, 0.05)',
      LoadingGradient: `
        linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.8) 60%,
            rgba(255, 255, 255, 0)
        )`,
    },
  },
}
export const Colors = {
  Cinnabar: {
    Toxic: '#fc5230',
  },
  Blue: {
    Dark: '#0668BE',
    Toxic: '#0596FA',
  },
}

export type ClassKey =
  | 'root'
  | 'inputRoot'
  | 'inputRootError'
  | 'input'
  | 'adornment'
  | 'adornmentEnd'
  | 'adornmentStart'
  | 'helperText'
  | 'textError'
  | 'label'

// YST/Body 2/Tight · Regular;
export const body2TightStyle = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: '19px',
}
// YST/Caption 1/Normal · Regular;
export const caption1NormalStyle = {
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '15px',
}
// YST/Caption 1/Tight · Regular;
export const caption1TightStyle = {
  fontSize: 13,
  fontWeight: 400,
  lineHeight: '14px',
}
const placeholderStyle = {
  color: Theme.Default.Day.TextMinor,
}
export const inputStyle = {
  font: 'inherit',
  color: 'currentColor',
  width: '100%',
  display: 'block',
  background: 'none',
  boxSizing: 'content-box',
  border: 0,
  margin: 0,
  letterSpacing: 'inherit',
  textOverflow: 'ellipsis',
  '&::-webkit-input-placeholder': placeholderStyle,
  '&::-moz-placeholder': placeholderStyle,
  '&:-ms-input-placeholder': placeholderStyle,
  '&::-ms-input-placeholder': placeholderStyle,
  '&:focus': {
    outline: 0,
  },
}

export const squircleClasses: Record<ClassKey, string> = {
  root: css`
    font-family: 'Yandex Sans Text Web', -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
  `,
  inputRoot: css`
    ${body2TightStyle};

    display: flex;
    background-clip: padding-box;
    background-color: ${Theme.Default.Day.BackgroundMinor};
    border: 2px solid ${Theme.Default.Day.BackgroundMinor};
    border-radius: ${spacing(2)};
    padding: ${spacing(0, 1.5)};
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

    &:focus-within {
      background: none;
      border-color: ${Theme.Default.Day.Focus};
    }
  `,
  inputRootError: css`
    background-color: ${hex2rgba(Colors.Cinnabar.Toxic, 0.1)};
    border-color: ${hex2rgba(Colors.Cinnabar.Toxic, 0.1)};

    &:focus-within {
      border-color: ${Colors.Cinnabar.Toxic};
    }
  `,
  input: css`
    ${inputStyle};

    padding: ${spacing(1.625, 0.25, 1.5)};

    [dir='rtl'] & {
      text-align: right;
    }
  `,
  adornment: css`
    display: flex;
    align-items: center;
  `,
  adornmentEnd: css`
    margin-left: ${spacing(1.5)};
    [dir='rtl'] & {
      margin-left: 0;
      margin-right: ${spacing(1.5)};
    }
  `,
  adornmentStart: css`
    margin-right: ${spacing(1.5)};
    [dir='rtl'] & {
      margin-right: 0;
      margin-left: ${spacing(1.5)};
    }
  `,
  helperText: css`
    ${caption1NormalStyle};

    margin-top: ${spacing(0.25)};
    color: ${Theme.Default.Day.TextMinor};
  `,
  label: '',
  textError: css`
    color: ${Colors.Cinnabar.Toxic};
  `,
}

const lineInputClass = css`
  ${inputStyle};

  padding: ${spacing(2.75, 0, 0.875)};
`
export const lineClasses: Record<ClassKey, string> = {
  root: squircleClasses.root,
  inputRoot: css`
    ${body2TightStyle};

    display: flex;
    position: relative;
    transition: box-shadow 0.2s ease-out;
    // -1px в конце нужен, чтобы убрать артефакты тени при масштабировании
    box-shadow: 0 -2px 0 -1px ${Theme.Default.Day.Line} inset;

    &:focus-within {
      box-shadow: 0 -3px 0 -1px ${Theme.Default.Day.BackgroundInvert} inset;
    }
  `,
  inputRootError: css`
    box-shadow: 0 -2px 0 -1px ${Colors.Cinnabar.Toxic} inset;

    &:focus-within {
      box-shadow: 0 -3px 0 -1px ${Colors.Cinnabar.Toxic} inset;
    }
  `,
  input: lineInputClass,
  adornment: squircleClasses.adornment,
  adornmentEnd: squircleClasses.adornmentEnd,
  adornmentStart: squircleClasses.adornmentStart,
  helperText: squircleClasses.helperText,
  label: css`
    ${body2TightStyle};

    pointer-events: none;
    color: ${Theme.Default.Day.TextMinor};
    position: absolute;
    top: ${spacing(2)};
    left: 0;
    transition: all 0.2s ease-out;

    .${lineInputClass}:focus ~ &,
    .${lineInputClass}:valid ~ & {
      ${caption1TightStyle};

      top: ${spacing(1)};
    }

    [dir='rtl'] & {
      left: auto;
      right: 0;
    }
  `,
  textError: css`
    color: ${Colors.Cinnabar.Toxic};
  `,
}

export const useSquircleClasses = makeStyles<ClassKey>(squircleClasses)
export const useLineClasses = makeStyles<ClassKey>(lineClasses)
