import clsx from 'clsx'
import {parseDigit, templateFormatter, templateParser} from 'input-format'
import InputFormat from 'input-format/react'
import React from 'react'

import {useLineClasses, useSquircleClasses} from './Input.classes'
import {InputProps as Props} from './types'

const Input = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    classes: classesProp,
    variant = 'squircle',
    error,
    startAdornment,
    endAdornment,
    helperText,
    className,
    label: labelProp,
    placeholder: placeholderProp,
    mask,
    ...otherProps
  } = props
  const useClasses = variant === 'squircle' ? useSquircleClasses : useLineClasses
  const classes = useClasses(classesProp)
  // label пока только у Line
  const label = variant === 'line' ? labelProp : undefined

  const inputProps: Props = {
    type: 'text',
    title: '',
    // очень не хотелось писать material анимацию на js
    // поэтому required нужен для корректной работы label с помощью css
    required: true,
    className: clsx(classes.input, className),
    // placeholder понадобился у всех типов инпута
    placeholder: placeholderProp,
    ...otherProps,
  }

  return (
    <div className={classes.root}>
      <div className={clsx(classes.inputRoot, error && classes.inputRootError)}>
        {startAdornment && (
          <div className={clsx(classes.adornment, classes.adornmentStart, error && classes.textError)}>
            {startAdornment}
          </div>
        )}
        {mask ? (
          <InputFormat
            ref={ref}
            format={templateFormatter(mask)}
            parse={templateParser(mask, parseDigit)}
            data-error={error}
            {...inputProps}
          />
        ) : (
          <input ref={ref} {...inputProps} data-error={error} />
        )}
        {endAdornment && (
          <div className={clsx(classes.adornment, classes.adornmentEnd, error && classes.textError)}>
            {endAdornment}
          </div>
        )}
        {label && <div className={clsx(classes.label, error && classes.textError)}>{label}</div>}
      </div>
      {helperText && <div className={clsx(classes.helperText, error && classes.textError)}>{helperText}</div>}
    </div>
  )
})

export default Input
