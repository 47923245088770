import {useAddress, useRoute, useTariffsState} from '@yandex-taxi/react-integration-api'
import React, {useMemo, useState} from 'react'

import {usePrices} from '../../hooks'
import {TariffPrices, Tariffs as TariffsEnum} from '../../types'
import {Grid} from '../grid'
import {clsx} from '../utils'
import {classes} from './Tariffs.classes'
import {ALL_TARIFFS, DEFAULT_TARIFF, TARIF_ERRORS, TARIFF_NAMES} from './Tariffs.consts'
import {isEmpty, localizePrice, toRoutePoints} from './Tariffs.utils'

const DEFAULT_PRICES: TariffPrices = {
  [TariffsEnum.Courier]: {value: '49', currency: 'RUB'},
  [TariffsEnum.Express]: {value: '99', currency: 'RUB'},
  [TariffsEnum.Cargo]: {value: '300', currency: 'RUB'},
}

const Tariffs: React.FC = () => {
  const [{value: source}] = useAddress(0)
  const [{value: destination}] = useAddress(1)
  const [{value: route = []}] = useRoute()
  const [tariff, setTariff] = useState(DEFAULT_TARIFF)
  const points = toRoutePoints(route)
  const tariffs = (useTariffsState().data || []).map(t => t.class)
  const {isLoading, result: prices, error} = usePrices(points, tariffs)

  const pricesWithFallback = useMemo(() => {
    if (isEmpty(prices) || !points[0]) {
      return DEFAULT_PRICES
    }
    return prices
  }, [prices, points])

  const clickHandler = ({currentTarget}) => {
    setTariff(currentTarget.dataset.tariff)
  }

  return tariffs ? (
    <Grid>
      {error && <div className={classes.helper}>{TARIF_ERRORS[error]}</div>}
      <Grid className={classes.root}>
        {ALL_TARIFFS.map((allowedTariff, key) => {
          const disabled = isLoading || isEmpty(prices) || !tariffs.includes(allowedTariff)
          const priceData = pricesWithFallback?.[allowedTariff]

          return (
            <div
              className={clsx(classes.button, tariff === allowedTariff && classes.tariffSelected)}
              data-tariff={allowedTariff}
              onClick={clickHandler}
              key={key}
            >
              <Grid container column className={disabled && classes.tariffDisabled}>
                <Grid className={classes[`tariff_${allowedTariff}`]} />
                <Grid className={classes.tariff}>{TARIFF_NAMES[allowedTariff] || allowedTariff}</Grid>
                <Grid className={classes.price}>
                  {(!source || !destination) && 'от'}{' '}
                  {priceData?.value ? localizePrice(priceData.value, priceData.currency) : '—'}
                </Grid>
              </Grid>
            </div>
          )
        })}
      </Grid>
    </Grid>
  ) : null
}

export default Tariffs
