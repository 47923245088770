export interface PropsWithClasses<ClassKey extends string = string> {
  classes?: Partial<ClassNameMap<ClassKey>>
  className?: string
}

export type ClassNameMap<ClassKey extends string = string> = Record<ClassKey, string>

export interface ClassOptions {
  name: string
}

export function makeStyles<ClassKey extends string = string>(
  baseClasses: ClassNameMap<ClassKey>,
  options?: ClassOptions,
) {
  return function mergeClasses(newClasses?: Partial<ClassNameMap<ClassKey>>): ClassNameMap<ClassKey> {
    const nextClasses = {...baseClasses}

    if (newClasses) {
      for (const key in newClasses) {
        if (process.env.NODE_ENV !== 'production') {
          if (!nextClasses[key] && newClasses[key]) {
            console.error(
              [
                `blocks: The key \`${key}\` provided to the classes prop is not implemented in ${
                  options?.name || '__COMPONENT_NAME__'
                }`,
                `You can only override one of the following: ${Object.keys(nextClasses).join(',')}.`,
              ].join('\n'),
            )
          }
        }

        if (newClasses[key]) {
          nextClasses[key] = `${nextClasses[key]} ${newClasses[key]}`
        }
      }
    }

    return nextClasses
  }
}
