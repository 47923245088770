import {css} from '@logistics-frontend/blocks/theme/css'

import {breakpoints, spacing} from '../consts'
import cargo from './images/cargo.svg'
import courier from './images/courier.svg'
import express from './images/express.svg'

export const classes = {
  root: css`
    display: flex;
    background-color: #f2f1ee;
    border-radius: ${spacing}px !important;
    padding: 2px;
    overflow: hidden;

    ${breakpoints.tablet} {
      border-radius: 10px !important;
    }
  `,
  button: css`
    border-radius: ${spacing}px !important;
    height: fit-content !important;
    text-align: start;
    padding: ${spacing * 2}px ${spacing}px 10px ${spacing}px !important;
    display: flex;
    flex-direction: column;
    justify-content: start;
    width: 100%;
    color: #5a5957;
    cursor: pointer;
    font-size: 13px;
    letter-spacing: normal;

    ${breakpoints.tablet} {
      padding-top: 10px !important;
    }
  `,
  tariff: css`
    line-height: 1.2;
    margin-bottom: 3px;
    font-weight: 500;
    padding: 0 ${spacing}px;
  `,
  tariffDisabled: css`
    opacity: 0.3;
  `,
  tariffSelected: css`
    color: #000;
    background: #fff;
  `,
  tariff_cargo: css`
    background-image: url('${cargo}');
    height: 20px;
    width: 49px;
    background-repeat: no-repeat;
    background-position: bottom left;
    margin: ${spacing}px 0 0 ${spacing}px;
    padding: 0;
    flex-basis: 20px;

    ${breakpoints.tablet} {
      width: 41px;
      height: 15px;
      background-size: auto 15px;
    }
  `,
  tariff_express: css`
    background-image: url('${express}');
    height: 20px;
    width: 49px;
    background-repeat: no-repeat;
    background-position: bottom left;
    margin: ${spacing}px 0 0 ${spacing}px;
    padding: 0;
    flex-basis: 20px;

    ${breakpoints.tablet} {
      width: 41px;
      height: 15px;
      background-size: auto 15px;
    }

  ,
  `,
  tariff_courier: css`
    background-image: url('${courier}');
    height: 20px;
    width: 49px;
    background-size: 45px 20px;
    background-repeat: no-repeat;
    background-position: bottom left;
    margin: ${spacing}px 0 0 ${spacing}px;
    padding: 0;
    flex-basis: 20px;

    ${breakpoints.tablet} {
      width: 41px;
      height: 15px;
      background-size: auto 15px;
    }
  `,
  tariffWrapper: css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
  `,
  price: css`
    line-height: 1;
    color: #000;
    white-space: nowrap;
    padding: 0 ${spacing}px ${spacing}px;

    ${breakpoints.tablet} {
      font-size: 11px;
    },
  `,
  loadingButton: css`
    position: relative;
    overflow: hidden;
    @keyframes loading {
      0% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(300%);
      }
    }
    &:before {
      content: '';
      pointer-events: none;
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: 33.3333%;
      animation: loading 1s infinite linear;
      transform: translateX(-100%);
    }
  `,
  helper: css`
    color: #fc5230;
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
    margin-top: -15px;
  `,
}
