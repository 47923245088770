import React from 'react'

import {clsx} from '../utils'
import {classes} from './Grid.classes'

type Props = {
  children?: React.ReactNode
  container?: boolean
  className?: string | boolean
  column?: boolean
}

const Grid: React.FC<Props> = ({container, children, className, column}) => (
  <div className={clsx(container ? classes.container : classes.item, column && classes.column, className && className)}>
    {children}
  </div>
)

export default Grid
