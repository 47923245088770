import {Tariffs} from '../../types'

export const ALL_TARIFFS = [Tariffs.Courier, Tariffs.Express, Tariffs.Cargo]

export const DEFAULT_TARIFF = Tariffs.Courier

export const TARIFF_NAMES: Record<Tariffs, string> = {
  [Tariffs.Courier]: 'Курьер',
  [Tariffs.Express]: 'Экспресс',
  [Tariffs.Cargo]: 'Грузовой',
}

export const TARIF_ERRORS: Record<string, string> = {
  route_too_long: 'Расстояние слишком большое',
}
