import {css} from '@logistics-frontend/blocks/theme/css'

import {breakpoints} from '../consts'

export const classes = {
  root: css`
    padding: 23px;
    background-color: #ffffff;
    border-radius: 16px;
    margin-bottom: 18px;
    ${breakpoints.mobile} {
      padding: 16px;
    },
  `,
  title: css`
    font-size: 26px;
    color: #000000;
    font-weight: 500;
    margin-bottom: 14px;
    line-height: 19px;
    ${breakpoints.mobile} {
      font-size: 21px;
    }
  `,
}
