import {CheckPriceResponse, RoutePoint} from '@logistics-frontend/types/schemas/cargo-matcher/definitions'
import {useEffect, useState} from 'react'

import {getBulkPrices} from '../api'
import {Price, TariffPrices, Tariffs} from '../types'

const mapResponsePriceToClient = (response: CheckPriceResponse): Price => ({
  value: response.price,
  currency: response.currency_rules.code,
})

const toTariffPrices = (responses: CheckPriceResponse[]): TariffPrices =>
  responses.reduce((acc, response) => {
    const priceData = mapResponsePriceToClient(response)
    return {...acc, [response.requirements.taxi_class as Tariffs]: priceData}
  }, {} as TariffPrices)

export const usePrices = (
  points: RoutePoint[],
  tariffs: string[],
): {isLoading: boolean; result: TariffPrices; error: string} => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [result, setResult] = useState<CheckPriceResponse[]>()
  const [error, setError] = useState<string>('')

  useEffect(() => {
    if (points.length > 0) {
      setIsLoading(true)
      setError('')
      // предполагаем что все тарифы описаны в енуме
      getBulkPrices(
        points,
        tariffs as Tariffs[],
        responses => {
          setIsLoading(false)
          setResult(responses)
        },
        err => {
          err.body.code.includes('route_too_long') && setError('route_too_long')
          console.warn(err)
        },
      )
    } else {
      setIsLoading(false)
      setResult(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(points), JSON.stringify(tariffs), setIsLoading])

  return {
    isLoading,
    result: result ? toTariffPrices(result) : undefined,
    error,
  }
}
