import {
  CheckPriceRequest,
  CheckPriceResponse,
  RoutePoint,
} from '@logistics-frontend/types/schemas/cargo-matcher/definitions'
import {createAuthProxyAPI, ProxyApiHeader} from '@yandex-taxi/js-integration-api'

import {Tariffs} from './types'
import {fetchWithHeaders as fetch} from './utils'

const tld: string = window.location.hostname.split('.').pop() as string
const host = (process.env.REACT_APP_AUTH_PROXY_HOST as string).replace('%tld%', tld)

export const getPriceRequest = (body: CheckPriceRequest) => {
  return fetch({
    url: '/api/b2b/cargo-matcher/v1/check-price',
    body,
    method: 'POST',
    options: {
      credentials: 'include',
    },
  })
}

export const getBulkPrices = (points: RoutePoint[], tariffs: Tariffs[], onSuccess: Function, onError: Function) => {
  const result: CheckPriceResponse[] = []
  const promises = Promise.allSettled(
    tariffs.map(tariff =>
      getPriceRequest({route_points: points, requirements: {taxi_class: tariff}})
        .then(response => result.push(response.body))
        .catch(err => onError?.(err)),
    ),
  )

  promises.then(requests => {
    if (requests.every(request => request.status === 'fulfilled')) {
      onSuccess(result)
    }
  })
}

export const startAuthorization = createAuthProxyAPI({
  host,
  path: 'integration/turboapp/v1',
  applicationDetectionRules: {
    'X-Taxi': 'yandex.delivery',
  },
  client: options => {
    const {url, body, method, headers, onSuccess, onError} = options
    const controller = new AbortController()

    fetch({
      url,
      body,
      method,
      headers,
      options: {
        credentials: 'include',
      },
      signal: controller.signal,
    })
      .then(({headers, body}) => {
        if (headers['x-yataxi-userid']) {
          headers[ProxyApiHeader.USER_ID] = headers['x-yataxi-userid']
          delete headers['x-yataxi-userid']
        }

        onSuccess?.(body, headers)
      })
      .catch(onError)

    return () => {
      controller.abort()
    }
  },
})
