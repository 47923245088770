import {SuggestObjectAction} from '@yandex-taxi/js-integration-api'
import {AsyncStatus, isGeoObject, isPart, useAddress, useSuggestQuery} from '@yandex-taxi/react-integration-api'
import React, {useCallback} from 'react'

import {Suggest} from '../suggest'

export interface Props {
  index: number
}

const Address: React.FC<Props> = ({index}) => {
  const [{value, touched, error: draftError}, {setValue, setTouched}] = useAddress(index)
  const {suggest, status, error: queryError} = useSuggestQuery(index)
  const blurHandler = useCallback(() => setTouched(true), [setTouched])
  const focusHandler = useCallback(() => setTouched(false), [setTouched])

  const part = isPart(value) ? value : undefined
  const geoObject = isGeoObject(value) ? value : undefined
  const inputValue = geoObject?.title.text || part
  const options = suggest?.filter(obj => obj.action !== SuggestObjectAction.SUBSTITUTE)
  const loading = status === AsyncStatus.PENDING
  const errorText = value && touched && draftError ? 'Неверный адрес' : undefined
  // @ts-ignore
  const helperText = status === AsyncStatus.ERROR && queryError?.body?.message ? queryError.body.message : errorText // @ts-ignore
  const isFirst = index === 0

  return (
    <Suggest
      helperText={helperText}
      type={isFirst ? 'source' : 'destination'}
      placeholder={isFirst ? 'Откуда' : 'Куда'}
      part={inputValue}
      suggest={options}
      geoObject={geoObject}
      loading={loading}
      onInputChange={setValue}
      onInputBlur={blurHandler}
      onInputFocus={focusHandler}
    />
  )
}

export default Address
