import React from 'react'

import {clsx} from '../utils'
import {classes} from './ListItem.classes'

type Props = {
  selected?: boolean
  title?: React.ReactNode
  subtitle: React.ReactNode
  onClick?: () => void
}

const ListItem: React.FC<Props> = props => {
  const {selected, title, subtitle, onClick} = props

  return (
    <div
      className={clsx(classes.listItem, selected && classes.selected)}
      onClick={onClick}
      onMouseDown={event => event.preventDefault()}
    >
      <div className={classes.body}>
        {title ? <div className={classes.titleText}>{title}</div> : null}
        {subtitle ? <div className={classes.subtitleText}>{subtitle}</div> : null}
      </div>
    </div>
  )
}

export default ListItem
