import {Exact} from '@iiiristram/ts-type-utils'
import superagent from 'superagent'

import {ResponseHeaders, TypedRequestConfig} from './types'

const createRequest = (url, method, body: any) => {
  const methodHandler = superagent[String(method).toLowerCase()]
  if (!methodHandler) {
    throw new Error(`Unsupported HTTP method: ${method}`)
  } else {
    return methodHandler(url, body)
  }
}

const superagentNetworkInterface = (url, method, {body, headers, credentials} = {}) => {
  const request = createRequest(url, method, body)

  if (headers) {
    request.set(headers)
  }

  if (credentials === 'include') {
    request.withCredentials()
  }

  if (
    request.get('content-type') === 'application/pdf' ||
    request.get('content-type') === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    request.responseType('blob')
  }

  const execute = cb =>
    request.end((err: Error, response: Response) => {
      const resStatus = (response && response.status) || 0
      const resBody = (response && response.body) || undefined
      const resText = (response && (response.text as any)) || undefined
      const resHeaders = (response && (response.headers as any)) || undefined

      cb(err, resStatus, resBody, resText, resHeaders)
    })

  const abort = () => request.abort()

  return {
    abort,
    execute,
  }
}

class ServerError extends Error {
  status: number
  originError: Error
  body: any
  headers?: Record<string, string>

  constructor(options: {error: Error; status: number; body: any; headers?: Record<string, string>}) {
    super(options.error.message)
    this.status = options.status
    this.originError = options.error
    this.body = options.body
    this.headers = options.headers
  }
}

export function fetchWithHeaders<TRequestBody = unknown, TResponseBody = unknown, TTransformedBody = unknown>(
  config: TypedRequestConfig<TRequestBody, TResponseBody, TTransformedBody>,
) {
  const {url, method = 'GET', body, headers, options, transform, signal} = config
  const {execute, abort} = superagentNetworkInterface(url, method, {
    body,
    headers,
    credentials: options?.credentials,
  })

  if (signal) {
    signal.addEventListener('abort', abort)
  }

  return new Promise<{
    body: Exact<TTransformedBody, any> extends true ? TResponseBody : TTransformedBody
    headers: ResponseHeaders
  }>((resolve, reject) => {
    execute((error, status, responseBody, responseText, responseHeaders) => {
      if (error || status >= 400) {
        reject(new ServerError({error, status, body: responseBody, headers: responseHeaders}))
        return
      }

      resolve({
        body: responseBody ? (transform ? transform(responseBody) : responseBody) : responseText,
        headers: responseHeaders || {},
      })
    })
  })
}
